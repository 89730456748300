import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// components
import Button from '../atoms/Button';
// Images
import { ReactComponent as LogoutLogo } from '../../assets/images/svg/logout.svg';
import logo from '../../assets/images/home-logo.png';
// Strings
import Strings from '../../assets/strings/strings';

const Header = ({ onLogout, firstname }) => {
  const history = useHistory();

  const goToProfile = () => {
    history.push('/account-info');
  };
  const goHome = () => {
    history.push('/');
  };

  return (
    <div className="header-container">
      <div className="m-margin-horizontal">
        <Button
          handleClick={goHome}
          content={<img src={logo} width="75px" alt="slv-logo" />}
          customClass="padding-0 btn-icon"
        />
      </div>

      <div className="l-margin-right d-flex justify-center align-center">
        <Button
          handleClick={onLogout}
          content={<LogoutLogo className="logout" />}
          customClass="btn-icon"
        />
        <span className="grey-text font-m xs-margin-right m-margin-left">
          {`${Strings.hello}, `}
        </span>
        <span className="dark-grey-text font-m m-margin-right">
          {firstname}
        </span>
        <Button
          handleClick={goToProfile}
          content={<span className="font-l">{firstname?.charAt(0)}</span>}
          customClass="btn-letter"
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  onLogout: PropTypes.func.isRequired,
  firstname: PropTypes.string,
};

Header.defaultProps = {
  firstname: '',
};

export default Header;
