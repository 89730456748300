export default {
  errors: {
    loginFieldError: 'Email ou mot de passe incorrects',
    wrongPassword: 'Mot de passe incorrect',
    pleaseFillField: 'Veuillez remplir ce champ',
    incorrectEmail: 'Cet email est incorrect',
    incorrectPassword:
        'Le mot de passe doit être de 8 caractères minimum et doit contenir au moins une majuscule et un chiffre.',
    readConditions: 'Vous devez avoir pris connaissance des conditions',
    emailAlreadyExist: 'Cette adresse e-mail est déjà utilisée',
    emailDontExist: "Cette adresse e-mail n'existe pas",
    generic: "Une erreur est survenue lors de l'enregistrement des données",
    billLoad: 'Une erreur est survenue lors du chargement des factures',
    userLoad: 'Une erreur est survenue lors du chargement des données utilisateur',
    loyaltyLoad: 'Une erreur est survenue lors du chargement des points fidélités',
    acceptCgv: 'Une erreur est survenue lors de l\'acceptation des CGV',
    fileTooBig: 'La taille du document sélectionné ne doit pas dépasser 4Mo',
  },
  login: 'Se connecter',
  register: "S'inscrire",
  createAccount: 'Création de compte',
  email: 'E-mail',
  password: 'Mot de passe',
  forgotPassword: 'Mot de passe oublié',
  doYouForgotYourPassword: 'Vous avez oublié votre mot de passe ?',
  forgotPasswordDesc: 'Saisissez votre adresse e-mail pour réinitialiser votre mot de passe',
  resetPassword: 'Réinitialiser le mot de passe',
  resetPasswordSuccess:
      'Suite à votre demande de réinitialisation de mot de passe '
      + 'un email vous a été adressé. Nous vous invitons à le consulter et à suivre la démarche indiquée.',
  see: 'Voir',
  hide: 'Cacher',
  activity: 'Activité',
  activitiesTitle: {
    distributor: 'Distributeur',
    installer: 'Installateur',
    paysagist: 'Paysagiste',
    generalCompany: 'Entreprise générale',
    prescriber: 'Prescripteur',
    other: 'Autre',
  },
  company: 'Société',
  validate: 'Valider',
  sponsorEmail: 'Email de votre parrain',
  optional: 'Facultatif',
  agreeCgu: {
    iAgree: 'Je reconnais avoir pris connaissance des ',
    cgu: "Conditions Générales d'utilisation",
    cgv: 'Conditions Générales de Vente',
  },
  agreeDataUse: {
    iAgree: 'Je reconnais avoir pris connaissance de la ',
    dataChart: 'charte des données',
  },
  cguTitle: 'CGU et mentions légales',
  dataChartTitle: 'Charte des données personnelles',
  hello: 'Bonjour',
  accountInfos: 'Informations de compte',
  billHistory: 'Historique des factures',
  lastname: 'Nom',
  firstname: 'Prénom',
  phone: 'Téléphone',
  address: 'Adresse',
  zipcode: 'Code postal',
  city: 'Ville',
  country: 'Pays',
  siret: 'SIRET',
  save: 'Sauvegarder',
  changePassword: {
    title: 'Modifier le mot de passe',
    desc:
        'Le mot de passe doit être long de 8 caractères minimum et doit contenir au moins une majuscule et un chiffre. '
        + 'Nous vous conseillons d\'y ajouter un caractère spécial pour une sécurité optimale.',
  },
  uploadBill: {
    title: 'importer une facture',
    desc: "Merci de masquer sur votre facture ou BL les informations concernant les produits d'autres fabricants.",
  },
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  changeSuccess: 'Modification effectuée avec succès',
  myBills: 'Mes factures',
  addBill: 'Envoyer une facture',
  loyaltyPoints: 'Points fidélité',
  billName: 'Nom de la facture',
  billAmount: 'Montant total HT des achats SLV',
  upload: 'Importer',
  loadFile: 'Charger un document',
  cgvModal: {
    title1: 'Merci de consulter et d\'accepter nos nouvelles',
    titleLink: 'conditions générales de vente',
    title2: "afin d'accéder à nos services",
    actionButton: "J'accepte les CGV",
  },
};
