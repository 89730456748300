import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Queries
import { useMutation, useQuery } from 'react-query';
// services
import UserService from '../services/UserService';
// Strings
import Strings from '../assets/strings/strings';
// Components
import Input from '../components/atoms/Input';
import Button from '../components/atoms/Button';
import { useAppContext } from '../store/AppContext';

const AccountInfos = () => {
  const [firstname, setFirstname] = useState('');
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastname, setLastname] = useState('');
  const [lastnameError, setLastnameError] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState(false);
  const [siret, setSiret] = useState('');

  const [, appDispatch] = useAppContext();

  const history = useHistory();

  const setUser = useMutation((updateInfo) => UserService.setUser(updateInfo));
  const { isSuccess, data: user } = useQuery(['userInfo', setUser.isSuccess], UserService.getUser);

  const initFields = () => {
    setFirstname(user?.data?.first_name);
    setLastname(user?.data?.last_name);
    setPhone(user?.data?.mobile_phone);
    setAddress(user?.data?.address);
    setZipcode(user?.data?.zip_code);
    setCity(user?.data?.city);
    setCountry(user?.data?.country);
    setSiret(user?.data?.siret);
  };

  const validateField = (value, setError) => {
    const isError = !value;
    setError(isError);
    return isError;
  };

  const updateUser = () => {
    const companyName = user?.data?.company_name;
    const role = user?.data?.role;
    const email = user?.data?.email;
    const sponsor = user?.data?.mail_sponsor;
    if (firstnameError
        || lastnameError
        || phoneError
        || addressError
        || zipcodeError
        || cityError
        || countryError
        || !companyName
        || !role
        || !email
    ) {
      return;
    }
    setUser.mutate({
      firstname,
      lastname,
      phone,
      address,
      zipcode,
      city,
      country,
      siret,
      companyName,
      role,
      email,
      mailSponsor: sponsor,
    });
  };

  useEffect(() => {
    if (setUser.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.changeSuccess, isSuccess: true },
      });
      history.push('/');
    }
    if (isSuccess) {
      initFields();
    }
    if (setUser.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.generic, isSuccess: false },
      });
    }
  }, [setUser.isSuccess, isSuccess, user, setUser.isError]);

  return (
    <div className="full-screen d-flex grey-background">
      <div className="d-flex justify-center align-center f-no-wrap full-width f-column">
        <h1 className="uppercase primary-text ls-5 text-center page-title">
          {Strings.accountInfos}
        </h1>
        <div className="w30-percent wmin-200 m-margin-top xl-margin-bottom d-flex f-column justify-center align-center">
          <div className="s-margin-vertical full-width">
            <Input
              onBlur={() => validateField(lastname, setLastnameError)}
              id="account-lastname"
              onChange={(value) => setLastname(value.toUpperCase())}
              value={lastname}
              label={Strings.lastname}
              isError={lastnameError}
            />
          </div>
          <div className="s-margin-vertical full-width">
            <Input
              onBlur={() => validateField(firstname, setFirstnameError)}
              id="account-firstname"
              onChange={setFirstname}
              value={firstname}
              label={Strings.firstname}
              isError={firstnameError}
            />
          </div>

          <div className="s-margin-vertical full-width">
            <Input
              onBlur={() => validateField(phone, setPhoneError)}
              id="account-phone"
              onChange={setPhone}
              value={phone}
              label={Strings.phone}
              isError={phoneError}
            />
          </div>

          <div className="s-margin-vertical full-width">
            <Input
              id="account-email"
              onChange={() => {}}
              value={user?.data?.email}
              label={Strings.email}
              disabled
            />
          </div>

          <div className="s-margin-vertical full-width">
            <Input
              onBlur={() => validateField(address, setAddressError)}
              id="account-address"
              onChange={setAddress}
              value={address}
              label={Strings.address}
              isError={addressError}
            />
          </div>

          <div className="s-margin-vertical full-width">
            <Input
              onBlur={() => validateField(zipcode, setZipcodeError)}
              id="account-zipcode"
              onChange={setZipcode}
              value={zipcode}
              label={Strings.zipcode}
              isError={zipcodeError}
            />
          </div>

          <div className="s-margin-vertical full-width">
            <Input
              onBlur={() => validateField(city, setCityError)}
              id="account-city"
              onChange={setCity}
              value={city}
              label={Strings.city}
              isError={cityError}
            />
          </div>

          <div className="s-margin-vertical full-width">
            <Input
              onBlur={() => validateField(country, setCountryError)}
              id="account-country"
              onChange={setCountry}
              value={country}
              label={Strings.country}
              isError={countryError}
            />
          </div>
          <div className="s-margin-vertical full-width">
            <Input
              id="account-siret"
              onChange={setSiret}
              value={siret}
              label={(
                <span>
                  {`${Strings.siret} - `}
                  <span className="italic">
                    {Strings.optional}
                  </span>
                </span>
              )}
            />
          </div>
          <div className="l-margin-bottom">
            <Button
              handleClick={updateUser}
              content={<span>{Strings.save}</span>}
              customClass="btn-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfos;
