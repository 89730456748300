import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import routes from './index';
import { useAppContext } from '../store/AppContext';
import LoggedInTemplate from '../templates/LoggedInTemplate';
import NotFoundScreen from '../views/404';

const Routes = () => {
  const [{ auth }] = useAppContext();

  const renderRoutes = routes.map((route) => (
    route.render
    // eslint-disable-next-line react/jsx-props-no-spreading
      ? <Route key={route.path} {...route} render={(args) => route.render(!!auth, args)} />
    // eslint-disable-next-line react/jsx-props-no-spreading
      : <Route key={route.path} {...route} />
  ));

  return (
    <Switch>
      { auth
        ? (
          <LoggedInTemplate routeComponent={renderRoutes} />
        )
        : renderRoutes}
      <Route component={NotFoundScreen} />

    </Switch>
  );
};

export default Routes;
