import React from 'react';
import PropTypes from 'prop-types';
import Strings from '../../assets/strings/strings';

const Checkbox = ({
  value, onChange, label, id, errorMessage, isError,
}) => (
  <div className="d-flex f-column full-width">
    <div className="d-flex f-row full-width">
      <input
        style={isError ? { border: '2px solid #d4364d' } : { border: '2px solid #fff' }}
        className="m-margin-right checkbox"
        type="checkbox"
        id={id}
        value={value}
        onChange={() => onChange(!value)}
      />
      <label className="white-text font-m" htmlFor={id}><span>{label}</span></label>
    </div>
    {
      isError
        ? (
          <div>
            <span className="error-text l-margin-left">{errorMessage}</span>
          </div>
        ) : null
      }
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: '',
  errorMessage: Strings.errors.readConditions,
  isError: false,
};

export default Checkbox;
