import React, { useEffect, useState } from 'react';
// images
import { useMutation } from 'react-query';
// import { useHistory } from 'react-router-dom';
import background from '../assets/images/bg.png';
// components
import Input from '../components/atoms/Input';
import Button from '../components/atoms/Button';
// strings
import Strings from '../assets/strings/strings';
// utils
import { isCorrectEmail } from '../utils/validators';
import UserService from '../services/UserService';
import { useAppContext } from '../store/AppContext';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const resetPasswordQuery = useMutation((data) => UserService.forgoPassword(data));

  const [, appDispatch] = useAppContext();

  const validateEmail = () => {
    const isError = !isCorrectEmail(email);
    setEmailError(isError);
    return isError;
  };

  const resetPassword = () => {
    if (validateEmail()) {
      return;
    }
    resetPasswordQuery.mutate({ email });
  };

  useEffect(() => {
    if (resetPasswordQuery.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.changeSuccess, isSuccess: true },
      });
    }
    if (resetPasswordQuery.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.emailDontExist, isSuccess: false },
      });
    }
  }, [resetPasswordQuery.isSuccess, resetPasswordQuery.isError]);

  return (
    <div style={{ backgroundImage: `url(${background})` }} className="full-screen background-cover d-flex">
      <div className="d-flex justify-center align-center f-no-wrap full-width f-column">
        <h1 className="uppercase white-text">{Strings.doYouForgotYourPassword}</h1>
        <p className="white-text font-m">{Strings.forgotPasswordDesc}</p>
        {resetPasswordQuery.isSuccess
          ? (
            <p className="uppercase fs-m success-text w30-percent text-center">
              {Strings.resetPasswordSuccess}
            </p>
          )
          : null}

        <div className="w30-percent m-margin-top d-flex f-column justify-center align-center">

          <div className="s-margin-vertical full-width">
            <Input
              onBlur={validateEmail}
              id="login-mail"
              onChange={setEmail}
              value={email}
              label={Strings.email}
              isError={emailError}
              errorMessage={Strings.errors.incorrectEmail}
            />
          </div>
          <div className="m-margin-top">
            <Button
              handleClick={resetPassword}
              content={<span>{Strings.resetPassword}</span>}
              customClass="btn-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
