import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

// Reducers
import appReducer from './appReducer';
import client from '../services/AxiosClient';
import UserService from '../services/UserService';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const initStore = {
  auth: localStorage.getItem('auth') || '',
  refresh: localStorage.getItem('refresh') || '',
  successPopover: { isOpen: false, title: '', isSuccess: true },
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initStore);

  useEffect(() => {
    localStorage.setItem('auth', state.auth);
    localStorage.setItem('refresh', state.refresh);
  }, [state]);

  // Axios interceptor to add token in header if we have it
  client.interceptors.request.use((config) => {
    // Add bearer in headers
    if (state.auth) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${state.auth}`;
    }
    return config;
  },
  (error) => Promise.reject(error));

  // axios response interceptor to refresh token and retry call when 401 is received
  const refreshTokenAndSave = () => UserService.refreshToken()
    .then(({ data: { access_token: accessToken, refresh_token: refresh } }) => {
      dispatch({ type: 'SET_AUTH', payload: accessToken });
      dispatch({ type: 'SET_REFRESH', payload: refresh });
      return accessToken;
    });

  client.interceptors.response.use((response) => response, (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      return refreshTokenAndSave().then((accessToken) => {
        // eslint-disable-next-line no-param-reassign
        error.config.headers.Authorization = `Bearer ${accessToken}`;
        // eslint-disable-next-line no-param-reassign
        error.config.baseURL = undefined;
        return client.request(error.config);
      })
        .catch((err) => Promise.reject(err));
    }
    return Promise.reject(error);
  });

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => [useContext(AppStateContext), useContext(AppDispatchContext)];

export {
  AppProvider,
  AppStateContext,
  AppDispatchContext,
  useAppContext,
};
