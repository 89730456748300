import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Strings from '../assets/strings/strings';
import Input from '../components/atoms/Input';
import { isCorrectPassword } from '../utils/validators';
import Button from '../components/atoms/Button';
import UserService from '../services/UserService';
import { useAppContext } from '../store/AppContext';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);

  const [, appDispatch] = useAppContext();

  const changePassword = useMutation((passwords) => UserService.changePassword(passwords));

  const history = useHistory();

  const validatePassword = () => {
    const isError = !isCorrectPassword(newPassword);
    setNewPasswordError(isError);
    return isError;
  };

  const handleClick = () => {
    if (validatePassword()) {
      return;
    }
    changePassword.mutate({
      oldPassword: Buffer.from(oldPassword).toString('base64'),
      newPassword: Buffer.from(newPassword).toString('base64'),
    });
  };

  useEffect(() => {
    if (changePassword.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.changeSuccess, isSuccess: true },
      });
      history.push('/');
    }
    if (changePassword.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.wrongPassword, isSuccess: false },
      });
    }
  }, [changePassword.isSuccess, changePassword.isError]);

  return (
    <div className="full-screen d-flex grey-background">
      <div className="d-flex align-center f-no-wrap full-width f-column">
        <h1 className="uppercase primary-text ls-5 text-center page-title">{Strings.changePassword.title}</h1>
        <p className="w30-percent grey-text wmin-200">{Strings.changePassword.desc}</p>
        <div className="w30-percent m-margin-top d-flex f-column justify-center align-center wmin-200">
          <div className="s-margin-vertical full-width">
            <Input
              id="password-old"
              onChange={setOldPassword}
              value={oldPassword}
              label={Strings.oldPassword}
              type="password"
              errorMessage={Strings.errors.incorrectPassword}
            />
          </div>
          <div className="s-margin-vertical full-width">
            <Input
              onBlur={validatePassword}
              id="password-new"
              onChange={setNewPassword}
              value={newPassword}
              label={Strings.newPassword}
              isError={newPasswordError}
              type="password"
              errorMessage={Strings.errors.incorrectPassword}
            />
          </div>
          <div className="l-margin-bottom">
            <Button
              handleClick={handleClick}
              content={<span>{Strings.validate}</span>}
              customClass="btn-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
