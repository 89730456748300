import client from './AxiosClient';

const login = ({ email, password }) => client.post('/oauth/v2/token', {
  client_id: process.env.REACT_APP_API_CLIENT_ID,
  client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
  grant_type: 'password',
  username: email,
  password,
});

const refreshToken = () => client.post('/oauth/v2/token', {
  client_id: process.env.REACT_APP_API_CLIENT_ID,
  client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
  grant_type: 'refresh_token',
  refresh_token: localStorage.getItem('refresh'),
});

const register = ({
  company, email, password, role, isInstaller, mailSponsor,
}) => client.post('/api/user/new', {
  companyName: company,
  email,
  mailSponsor,
  password,
  role,
  isInstaller,
});

const forgoPassword = ({ email }) => client.post('/api/user/forgot-password', {
  email,
});

const getUser = () => client.get(
  '/api/user',
);

const setUser = ({
  firstname, lastname, phone, address, zipcode, city, country, siret, companyName, role, email, mailSponsor,
}) => client.post(
  '/api/user/edit',
  {
    lastName: lastname,
    firstName: firstname,
    mobilePhone: phone,
    address,
    zipCode: zipcode,
    city,
    country,
    siret,
    companyName,
    role,
    email,
    mailSponsor,
  },
);

const changePassword = ({ oldPassword, newPassword }) => client.post(
  '/api/user/edit/password',
  {
    oldPassword,
    newPassword,
  },
);

const acceptCgv = () => client.post(
  '/api/user/accept-cgv',
);

export default {
  login, refreshToken, register, forgoPassword, getUser, setUser, changePassword, acceptCgv,
};
