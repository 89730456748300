import Strings from '../assets/strings/strings';

export const activities = [
  {
    label: Strings.activitiesTitle.distributor,
    value: 'distributor',
  },
  {
    label: Strings.activitiesTitle.installer,
    value: 'installer',
  },
  {
    label: Strings.activitiesTitle.prescriber,
    value: 'prescriber',
  },
  {
    label: Strings.activitiesTitle.other,
    value: 'other',
  },
  {
    label: Strings.activitiesTitle.paysagist,
    value: 'paysagist',
  },
  {
    label: Strings.activitiesTitle.generalCompany,
    value: 'general_company',
  },
];

export const cguLink = 'https://api.france-slv.com/upload/cgu.pdf';

export const dataChartLink = 'https://api.france-slv.com/upload/charte.pdf';

export const cgvLink = 'https://api.france-slv.com/upload/cgv.pdf';
