import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import loader from '../../assets/lottie/loader.json';

const Loader = ({ width }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };

  return (
    <Lottie
      options={lottieOptions}
      width={width}
      height={width * 2}
    />
  );
};

Loader.propTypes = {
  width: PropTypes.number,
};

Loader.defaultProps = {
  width: 50,
};

export default Loader;
