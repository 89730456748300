import React from 'react';

const NotFoundScreen = () => (
  <div
    className="d-flex f-column justify-center align-center full-screen"
  >
    <p className="font-xl fw-bold">404</p>
    <p className="font-l fw-bold">Page not found</p>
  </div>
);

export default NotFoundScreen;
