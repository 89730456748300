import React, { useEffect, useState } from 'react';
// Libraries
import { useMutation } from 'react-query';
// Images
import { Link, useHistory } from 'react-router-dom';
import background from '../assets/images/bg.png';
import logo from '../assets/images/logo_blanc@3x.png';
// Components
import Input from '../components/atoms/Input';
import UserService from '../services/UserService';
import Button from '../components/atoms/Button';
import { useAppContext } from '../store/AppContext';
import Strings from '../assets/strings/strings';
import { isCorrectEmail, isCorrectPassword } from '../utils/validators';

const Login = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const login = useMutation((loginInfo) => UserService.login(loginInfo));

  const history = useHistory();

  const [, appDispatch] = useAppContext();

  useEffect(() => {
    if (login.isSuccess) {
      appDispatch({ type: 'SET_AUTH', payload: login.data.data.access_token });
      appDispatch({ type: 'SET_REFRESH', payload: login.data.data.refresh_token });
    }
  }, [login.isSuccess]);

  const validateEmail = () => {
    const isError = !isCorrectEmail(email);
    setEmailError(isError);
    return isError;
  };

  const validatePassword = () => {
    const isError = !isCorrectPassword(password);
    setPasswordError(isError);
    return isError;
  };

  const validation = () => {
    const isEmailError = validateEmail();
    const isPasswordError = validatePassword();
    return (!isEmailError && !isPasswordError);
  };
  const handleClick = () => {
    if (!validation()) {
      return;
    }
    login.mutate({ email, password });
  };

  const goToRegister = () => {
    history.push('/register');
  };

  return (
    <div style={{ backgroundImage: `url(${background})` }} className="full-screen background-cover d-flex">
      <div className="d-flex justify-center align-center f-no-wrap full-width f-column">
        <img src={logo} alt="slv-logo" width="30%" />
        <div className="w30-percent m-margin-top d-flex f-column justify-center align-center">
          {login.isError
            ? <p className="uppercase fs-m error-text">{Strings.errors.loginFieldError}</p> : null}
          <div className="s-margin-vertical full-width">
            <Input
              onBlur={validateEmail}
              id="login-mail"
              onChange={setEmail}
              value={email}
              label={Strings.email}
              isError={emailError}
              errorMessage={Strings.errors.incorrectEmail}
            />
          </div>
          <div className="xl-margin-bottom full-width d-flex f-column justify-center align-end">
            <Input
              onBlur={validatePassword}
              id="login-password"
              onChange={setPassword}
              value={password}
              label={Strings.password}
              isError={passwordError}
              type="password"
              errorMessage={Strings.errors.incorrectPassword}
            />
            <Link
              to="/forgot-password"
              className="italic white-text underline"
            >
              {Strings.forgotPassword}
            </Link>
          </div>
          <Button
            handleClick={handleClick}
            content={<span>{Strings.login}</span>}
            customClass="btn-primary"
          />
          <div className="m-margin-top">
            <Button
              handleClick={goToRegister}
              content={<span>{Strings.register}</span>}
              customClass="btn-secondary"
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Login;
