import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { cgvLink } from '../../utils/constants';
import Strings from '../../assets/strings/strings';
import Button from '../atoms/Button';
import UserService from '../../services/UserService';
import { useAppContext } from '../../store/AppContext';

const CgvModal = ({ closeModal }) => {
  const modalContentRef = useRef();
  const [, appDispatch] = useAppContext();

  const acceptCgvQuery = useMutation(UserService.acceptCgv, {
    onSuccess: () => {
      closeModal();
    },
    onError: () => {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.acceptCgv, isSuccess: false },
      });
    },
  });

  return (
    <div className="action-modal">
      <div ref={modalContentRef}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className="action-modal-content"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <div className="d-flex f-column justify-between xl-padding align-center">
            <h1 className="secondary-text font-l text-center l-margin-bottom">
              {Strings.cgvModal.title1}
              <a
                className="s-margin-horizontal secondary-text underline pointer"
                href={cgvLink}
                target="_blank"
                rel="noreferrer"
              >
                {Strings.cgvModal.titleLink}
              </a>
              {Strings.cgvModal.title2}
            </h1>
            <div className="l-margin-vertical">
              <Button
                handleClick={() => acceptCgvQuery.mutate()}
                content={Strings.cgvModal.actionButton}
                customClass="btn-primary"
                disabled={acceptCgvQuery?.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CgvModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CgvModal;
