import React from 'react';
import Strings from '../../assets/strings/strings';
import Basket from '../../assets/images/basket.png';
import Credit from '../../assets/images/credit.png';
import ProfileIcon from '../../assets/images/profil.png';
import Password from '../../assets/images/password.png';

const LeftMenu = () => {
  const menuItems = [
    { title: Strings.billHistory, icon: Basket, path: '/bill-history' },
    { title: Strings.addBill, icon: Credit, path: '/add-bill' },
    { title: Strings.accountInfos, icon: ProfileIcon, path: '/account-info' },
    { title: Strings.password, icon: Password, path: '/change-password' },
  ];
  const currentPath = window.location.href;
  return (
    <div className="left-menu-container d-flex f-column align-center absolute xl-padding-vertical">
      {
        menuItems.map((item) => (
          <a
            key={item.title}
            style={currentPath.includes(item.path) ? { opacity: 0.5 } : { opacity: 1 }}
            className="l-margin-vertical normal-style no-decoration m-margin-horizontal grey-text tooltip menu-hover"
            href={item.path}
          >
            <img src={item.icon} alt="menu-icon" width={25} className="menu-icon" />
            <div className="tooltip-text"><span>{item.title}</span></div>
          </a>
        ))
      }
    </div>
  );
};

LeftMenu.propTypes = {

};

export default LeftMenu;
