import React from 'react';
import PropTypes from 'prop-types';

function Button({
  handleClick, content, customClass, disabled,
}) {
  return (
    <button
      className={`btn ${customClass} ${disabled ? 'btn-disabled' : ''}`}
      type="button"
      onClick={handleClick}
    >
      {content}
    </button>
  );
}

Button.propTypes = {
  handleClick: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  customClass: '',
  disabled: false,
};

export default Button;
