import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Strings from '../../assets/strings/strings';
import closedEye from '../../assets/images/off.png';
import openedEye from '../../assets/images/on.png';

const Input = ({
  value, onChange, label, type, id, errorMessage, isError, onBlur, disabled,
}) => {
  const [localType, setLocalType] = useState(type);
  const [eyeImg, setEyeImg] = useState(closedEye);

  const togglePassword = (e) => {
    e.stopPropagation();
    const newImg = eyeImg === closedEye ? openedEye : closedEye;
    const newType = localType === 'password' ? 'text' : 'password';
    setEyeImg(newImg);
    setLocalType(newType);
  };

  return (
    <label className="input-yellow-label d-flex f-column full-width" htmlFor={id}>
      <span>{label}</span>
      <div className="relative full-width d-flex justify-center f-column">
        <input
          className="input"
          type={localType}
          id={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => {
            onBlur();
          }}
          disabled={disabled}
        />
        <span className="bar" />
        {
          isError
            ? (
              <span className="bar error-bar" />
            ) : null
        }
        {
          type === 'password'
            ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
              <div
                className="absolute eye-icon d-flex align-center"
                onClick={togglePassword}
              >
                <span className="uppercase grey-text font-s s-margin-right">
                  {localType === 'password' ? Strings.see : Strings.hide}
                </span>
                <img alt="closed-eye" src={eyeImg} width="20px" />
              </div>
            )
            : null
        }
      </div>
      {
          isError
            ? (
              <div>
                <span className="error-text">{errorMessage}</span>
              </div>
            ) : null
        }
    </label>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  type: 'text',
  errorMessage: Strings.errors.pleaseFillField,
  isError: false,
  onBlur: () => {},
  disabled: false,
};

export default Input;
