import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import Strings from '../assets/strings/strings';
import BillService from '../services/BillService';
import Loader from '../components/atoms/Loader';
import { useAppContext } from '../store/AppContext';
import File from '../assets/images/file.png';
import Button from '../components/atoms/Button';

const BillHistory = () => {
  const {
    isLoading, isError, data: bills,
  } = useQuery('getBills', BillService.getBills);

  const [, appDispatch] = useAppContext();

  useEffect(() => {
    if (isError) {
      appDispatch({ type: 'SET_POPOVER', payload: { isOpen: true, title: Strings.errors.billLoad, isSuccess: false } });
    }
  }, [isError, bills]);

  const openMedia = (media) => {
    const win = window.open(`${process.env.REACT_APP_API_URL}/${media.url}`, '_blank');
    if (win) {
      win.focus();
    }
  };

  return (
    <div className="full-screen d-flex grey-background">
      <div className="d-flex align-center f-no-wrap full-width f-column">
        <h1 className="uppercase primary-text ls-5">{Strings.myBills}</h1>
        <div className="m-margin-top d-flex f-column justify-center align-center" />
        {
            isLoading || isError
              ? (
                <Loader />
              )
              : (
                <div className="m-margin-top d-flex f-column justify-center align-center full-width xxl-margin-bottom">
                  {
                    bills.data.map((bill) => (
                      <div
                        key={`${bill.name}-${bill.billing_date}-${bill.amount}`}
                        className="bill-container d-flex f-column s-margin-vertical"
                      >
                        <span className="uppercase primary-text ls-5 s-margin font-l">
                          {format(new Date(bill.billing_date), 'MMMM yyyy', { locale: fr })}
                        </span>
                        <div className="d-flex f-row justify-between align-center">
                          <div className="d-flex f-column">

                            <span className="dark-grey-text font-l s-margin">{bill.name}</span>
                            <span className="s-margin-horizontal s-margin-bottom grey-text font-m">
                              {format(new Date(bill.billing_date), 'dd.MM.yyyy', { locale: fr })}
                            </span>
                          </div>
                          <span className="dark-grey-text font-l">
                            {bill.amount.toFixed(2)}
                          </span>
                        </div>
                        <div className="d-flex f-wrap justify-end align-center full-width">
                          {bill.medias.map((media) => (
                            <Button
                              key={media}
                              handleClick={() => openMedia(media)}
                              content={<img src={File} width="20px" alt="file-icon" />}
                              customClass="btn-icon s-margin-horizontal"
                            />
                          ))}
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
                  }
      </div>
    </div>
  );
};

export default BillHistory;
