import React, { useEffect } from 'react';
import Button from '../atoms/Button';
import { useAppContext } from '../../store/AppContext';

const AppPopover = () => {
  const [{ successPopover: { isOpen, title, isSuccess } }, appDispatch] = useAppContext();

  const hide = () => {
    appDispatch({ type: 'SET_POPOVER', payload: { isOpen: false, title: '', isSuccess } });
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(hide, 2000);
    }
  }, [isOpen]);

  return (
    isOpen
      ? (
        <div className={isSuccess ? 'popover-container popover-success' : 'popover-container popover-error'}>
          <span>{title}</span>
          <Button
            handleClick={hide}
            content={(
              <span
                className={isSuccess ? 'close-button close-button-success' : 'close-button close-button-error'}
              >
                X
              </span>
              )}
            customClass="btn-no-style"
          />
        </div>
      ) : null
  );
};

export default AppPopover;
