// Views
import { Redirect } from 'react-router-dom';
import Login from '../views/Login';
import Register from '../views/Register';
import ForgotPassword from '../views/ForgotPassword';

export default [
  {
    path: '/login',
    exact: false,
    render: (auth, { location }) => (
      auth ? <Redirect to={{ pathname: '/', state: { from: location } }} /> : <Login />
    ),
  },
  {
    path: '/register',
    exact: false,
    component: Register,
  },
  {
    path: '/forgot-password',
    exact: false,
    component: ForgotPassword,
  },
];
