import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
// store and service
import { useAppContext } from '../store/AppContext';
import UserService from '../services/UserService';
// components
import Header from '../components/Molecules/Header';
import Footer from '../components/Molecules/Footer';
// Strings
import Strings from '../assets/strings/strings';
import LeftMenu from '../components/Molecules/LeftMenu';

// eslint-disable-next-line react/prop-types
const LoggedInTemplate = ({ routeComponent }) => {
  const [, appDispatch] = useAppContext();

  const { isError, data: user } = useQuery('getuser', UserService.getUser);

  useEffect(() => {
    if (isError) {
      appDispatch({ type: 'SET_POPOVER', payload: { isOpen: true, title: Strings.errors.userLoad, isSuccess: false } });
    }
  }, [isError, user]);

  const onLogout = () => {
    appDispatch({ type: 'SET_AUTH', payload: '' });
    appDispatch({ type: 'SET_REFRESH', payload: '' });
  };
  return (
    <div>
      {!isError ? <Header firstname={user?.data?.first_name} onLogout={onLogout} /> : null}
      {routeComponent}
      <LeftMenu />
      <Footer />
    </div>
  );
};

export default LoggedInTemplate;
