import React from 'react';
import Strings from '../../assets/strings/strings';
import { dataChartLink, cguLink } from '../../utils/constants';

const Footer = () => (
  <div className="footer-container">
    <div>
      <a
        className="footer-link normal-style no-decoration m-margin-horizontal grey-text"
        href={dataChartLink}
        target="_blank"
        rel="noreferrer"
      >
        {Strings.dataChartTitle}
      </a>
      <a
        className="footer-link normal-style no-decoration m-margin-horizontal grey-text"
        href={cguLink}
        target="_blank"
        rel="noreferrer"
      >
        {Strings.cguTitle}
      </a>
    </div>
    <span className="uppercase dark-primary-text font-l">{`SLV ${new Date().getFullYear()}`}</span>
  </div>
);

export default Footer;
