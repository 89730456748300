import axiosClient from './AxiosClient';

const getBills = () => axiosClient.get('/api/bill/history');

const getPoints = () => axiosClient.get('/api/user/points');

const addBill = ({
  amount, name, fileName, file,
}) => axiosClient.put(
  '/api/bill/new',
  {
    amount,
    name,
    file: {
      title: fileName,
      value: [file],
    },
    billPromo: [],
  },
);

export default { getBills, getPoints, addBill };
