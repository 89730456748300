// Views
import {
  Redirect,
} from 'react-router-dom';

import Home from '../views/Home';
import AccountInfos from '../views/AccountInfos';
import ChangePassword from '../views/ChangePassword';
import BillHistory from '../views/BillHistory';
import AddBill from '../views/AddBill';

export default [
  {
    path: '/',
    exact: true,
    render: (auth, { location }) => (
      auth ? <Home /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
    ),
  },
  {
    path: '/account-info',
    exact: true,
    render: (auth, { location }) => (
      auth ? <AccountInfos /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
    ),
  },
  {
    path: '/change-password',
    exact: true,
    render: (auth, { location }) => (
      auth ? <ChangePassword /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
    ),
  },
  {
    path: '/bill-history',
    exact: true,
    render: (auth, { location }) => (
      auth ? <BillHistory /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
    ),
  },
  {
    path: '/add-bill',
    exact: true,
    render: (auth, { location }) => (
      auth ? <AddBill /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
    ),
  },
];
