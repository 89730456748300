import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
// components
import MenuTiles from '../components/Molecules/MenuTiles';
// images
import ProfileIcon from '../assets/images/profil.png';
import Password from '../assets/images/password.png';
import Basket from '../assets/images/basket.png';
import Credit from '../assets/images/credit.png';
import BillService from '../services/BillService';
// Strings
import Strings from '../assets/strings/strings';
// store
import { useAppContext } from '../store/AppContext';
import CgvModal from '../components/Molecules/CgvModal';
import UserService from '../services/UserService';

const Home = () => {
  const [isCgvModalOpen, setIsCgvModalOpen] = useState(false);

  const history = useHistory();
  const [, appDispatch] = useAppContext();

  const {
    isError, data: points,
  } = useQuery('getPoints', BillService.getPoints);
  const { isError: isUserError, data: user } = useQuery('getuser', UserService.getUser);

  useEffect(() => {
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.loyaltyLoad, isSuccess: false },
      });
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.userLoad, isSuccess: false },
      });
    }
  }, [isError, isUserError]);

  useEffect(() => {
    if (user && !user.data?.has_accepted_cgv) {
      setIsCgvModalOpen(true);
    }
  }, [user]);

  const goToAccount = () => history.push('/account-info');
  const goToChangePassword = () => history.push('/change-password');
  const goToBillHistory = () => history.push('/bill-history');
  const goToAddBill = () => history.push('/add-bill');

  return (
    <div
      className="d-flex f-column justify-center align-center relative xl-margin-bottom"
    >
      {!isError ? (
        <div className="absolute home-point-circle d-flex justify-center f-column">
          <span className="dark-grey-text font-xl fw-light">{points?.data?.points}</span>
          <span className="dark-grey-text font-m uppercase">{Strings.loyaltyPoints}</span>
        </div>
      ) : null}
      <div
        className="d-flex f-row f-wrap justify-center align-center l-margin-bottom menu-tiles-container"
      >
        <MenuTiles icon={Credit} title={Strings.addBill} onPress={goToAddBill} boxStyle="profile-box-highlight" />
        <MenuTiles icon={Basket} title={Strings.billHistory} onPress={goToBillHistory} />
        <MenuTiles icon={ProfileIcon} title={Strings.accountInfos} onPress={goToAccount} />
        <MenuTiles icon={Password} title={Strings.password} onPress={goToChangePassword} />
      </div>
      {
        isCgvModalOpen
          ? <CgvModal closeModal={() => setIsCgvModalOpen(false)} />
          : null
      }
    </div>
  );
};

export default Home;
