import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';

// components
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Input from '../components/atoms/Input';
// images
import background from '../assets/images/bg.png';
// strings
import Strings from '../assets/strings/strings';
// utils
import { isCorrectEmail, isCorrectPassword } from '../utils/validators';
import { activities, cguLink, dataChartLink } from '../utils/constants';
import Button from '../components/atoms/Button';
import Checkbox from '../components/atoms/Checkbox';
// Services
import UserService from '../services/UserService';
import { isInstaller } from '../utils/utils';
import { useAppContext } from '../store/AppContext';

const Register = () => {
  // fields and errors
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [activity, setActivity] = useState('');
  const [activityError, setActivityError] = useState(false);
  const [company, setCompany] = useState('');
  const [companyError, setCompanyError] = useState(false);
  const [sponsor, setSponsor] = useState('');
  const [sponsorError, setSponsorError] = useState(false);
  const [isCgu, setIsCgu] = useState(false);
  const [isCguError, setIsCguError] = useState(false);
  const [isCgv, setIsCgv] = useState(false);
  const [isCgvError, setIsCgvError] = useState(false);
  const [isChart, setIsChart] = useState(false);
  const [isChartError, setIsChartError] = useState(false);

  const register = useMutation((registerInfo) => UserService.register(registerInfo));

  const history = useHistory();

  const [, appDispatch] = useAppContext();

  useEffect(() => {
    if (register.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.changeSuccess, isSuccess: true },
      });
      history.push('/login');
    }
    if (register.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.emailAlreadyExist, isSuccess: false },
      });
    }
  }, [register.isSuccess, register.isError]);

  const validateisCgu = (value = isCgu) => {
    const isError = !value;
    setIsCguError(isError);
    return isError;
  };

  const validateisCgv = (value = isCgv) => {
    const isError = !value;
    setIsCgvError(isError);
    return isError;
  };

  const validateisChart = (value = isChart) => {
    const isError = !value;
    setIsChartError(isError);
    return isError;
  };

  const validateSponsor = () => {
    const isError = sponsor ? !isCorrectEmail(sponsor) : false;
    setSponsorError(isError);
    return isError;
  };

  const validateActivity = (value = activity) => {
    const isError = !value || !value?.value;
    setActivityError(isError);
    return isError;
  };

  const validateCompany = () => {
    const isError = !company;
    setCompanyError(isError);
    return isError;
  };

  const validateEmail = () => {
    const isError = !isCorrectEmail(email);
    setEmailError(isError);
    return isError;
  };

  const validatePassword = () => {
    const isError = !isCorrectPassword(password);
    setPasswordError(isError);
    return isError;
  };

  const validation = () => {
    const isEmailError = validateEmail();
    const isPasswordError = validatePassword();
    const isActivityError = validateActivity();
    const isCompanyError = validateCompany();
    const isSponsorError = validateSponsor();
    const cguError = validateisCgu();
    const cgvError = validateisCgv();
    const chartError = validateisChart();
    return (
      !isEmailError
        && !isActivityError
        && !isPasswordError
        && !isCompanyError
        && !isSponsorError
        && !cguError
        && !cgvError
        && !chartError
    );
  };

  const onActivityChange = (value) => {
    setActivity(value || '');
    validateActivity(value);
  };

  const onIsCguChange = (value) => {
    setIsCgu(value);
    validateisCgu(value);
  };

  const onIsCgvChange = (value) => {
    setIsCgv(value);
    validateisCgv(value);
  };

  const onIsChartChange = (value) => {
    setIsChart(value);
    validateisChart(value);
  };

  const handleClick = () => {
    if (!validation()) {
      return;
    }
    register.mutate({
      email,
      password: Buffer.from(password).toString('base64'),
      company,
      role: activity.value,
      isInstaller: isInstaller(activity.value),
      mailSponsor: sponsor,
    });
  };

  return (
    <div style={{ backgroundImage: `url(${background})` }} className="full-screen background-cover d-flex">
      <div className="d-flex justify-center align-center f-no-wrap full-width f-column">
        <h1 className="uppercase white-text ls-5">{Strings.createAccount}</h1>
        <div className="w30-percent m-margin-top d-flex f-column justify-center align-center">
          <div className="s-margin-vertical full-width">
            <Input
              onBlur={validateEmail}
              id="register-mail"
              onChange={setEmail}
              value={email}
              label={Strings.email}
              isError={emailError}
              errorMessage={Strings.errors.incorrectEmail}
            />
          </div>
          <div className="full-width s-margin-vertical">
            <Input
              onBlur={validatePassword}
              id="login-password"
              onChange={setPassword}
              value={password}
              label={Strings.password}
              isError={passwordError}
              type="password"
              errorMessage={Strings.errors.incorrectPassword}
            />
          </div>
          <div className="full-width">
            <Input
              onBlur={validateCompany}
              id="register-password"
              onChange={setCompany}
              value={company}
              label={Strings.company}
              isError={companyError}
            />
          </div>
          <div className="xl-margin-bottom full-width">
            <span className="font-m primary-text d-flex f-column full-width xs-margin-bottom">{Strings.activity}</span>
            <Select
              isClearable
              name={Strings.activity}
              options={activities}
              placeholder={`${Strings.activity}...`}
              onChange={(value) => onActivityChange(value)}
              styles={{
                placeholder: () => ({
                  fontSize: '0.8rem',
                }),
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  boxShadow: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  fontSize: '0.8rem',
                }),
                menuList: (provided) => ({
                  ...provided,
                  fontSize: '0.8rem',
                }),
              }}
            />
            {
              activityError
                ? (
                  <div>
                    <span className="error-text">{Strings.errors.pleaseFillField}</span>
                  </div>
                ) : null
            }
          </div>
          {
            activity.value === 'installer'
              ? (
                <div className="full-width">
                  <Input
                    onBlur={validateSponsor}
                    id="register-sponsor"
                    onChange={setSponsor}
                    value={sponsor}
                    label={(
                      <span>
                        {`${Strings.sponsorEmail} - `}
                        <span className="italic">
                          {Strings.optional}
                        </span>
                      </span>
                    )}
                    isError={sponsorError}
                    errorMessage={Strings.errors.incorrectEmail}
                  />
                </div>
              )
              : null
          }
          <div className="m-margin-bottom">
            <Checkbox
              id="register-cgu"
              onChange={onIsCguChange}
              value={isCgu}
              label={(
                <span>
                  {Strings.agreeCgu.iAgree}
                  <a
                    className="italic white-text underline normal-style"
                    href={cguLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {Strings.agreeCgu.cgu}
                  </a>
                </span>
              )}
              isError={isCguError}
            />
          </div>

          <div className="m-margin-bottom">
            <Checkbox
              id="register-cgv"
              onChange={onIsCgvChange}
              value={isCgv}
              label={(
                <span>
                  {Strings.agreeCgu.iAgree}
                  <a
                    className="italic white-text underline normal-style"
                    href={cguLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {Strings.agreeCgu.cgv}
                  </a>
                </span>
                )}
              isError={isCgvError}
            />
          </div>

          <div className="l-margin-bottom">
            <Checkbox
              id="register-chart"
              onChange={onIsChartChange}
              value={isChart}
              label={(
                <span>
                  {Strings.agreeDataUse.iAgree}
                  <a
                    className="italic white-text underline normal-style"
                    href={dataChartLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {Strings.agreeDataUse.dataChart}
                  </a>
                </span>
                )}
              isError={isChartError}
            />
          </div>
          <div className="l-margin-bottom">
            <Button
              handleClick={handleClick}
              content={<span>{Strings.validate}</span>}
              customClass="btn-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
