import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Strings from '../assets/strings/strings';
import Input from '../components/atoms/Input';
import Button from '../components/atoms/Button';
import { useAppContext } from '../store/AppContext';
import Trash from '../assets/images/trash.png';
import BillService from '../services/BillService';

const AddBill = () => {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [file, setFile] = useState(undefined);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();

  const [, appDispatch] = useAppContext();

  const history = useHistory();

  const addBillQuery = useMutation((addBillInfos) => BillService.addBill(addBillInfos));

  const getBase64 = (fileToConvert) => new Promise((resolve) => {
    let baseURL = '';
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(fileToConvert);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });

  // Check if file size is under 4 Mo
  const validateFileSize = () => file.size <= 4000000;

  const onSubmit = () => {
    setLoading(true);
    if (validateFileSize()) {
      getBase64(file).then((base64) => {
        addBillQuery.mutate({
          amount, name, fileName, file: base64,
        });
        setLoading(false);
      });
    } else {
      setLoading(false);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.fileTooBig, isSuccess: false },
      });
    }
  };

  useEffect(() => {
    if (addBillQuery.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.changeSuccess, isSuccess: true },
      });
      history.push('/bill-history');
    }
    if (addBillQuery.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: Strings.errors.generic, isSuccess: false },
      });
    }
  }, [addBillQuery.isSuccess, addBillQuery.isError]);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="full-screen d-flex grey-background">
      <div className="d-flex align-center f-no-wrap full-width f-column">
        <h1 className="uppercase primary-text ls-5 text-center page-title">{Strings.uploadBill.title}</h1>
        <p className="w30-percent grey-text wmin-200">{Strings.uploadBill.desc}</p>
        <div className="w30-percent m-margin-top d-flex f-column justify-center align-center wmin-200">
          <div className="s-margin-vertical full-width relative">
            <div className="l-margin-vertical d-flex f-column">
              <input
                type="file"
                ref={fileInputRef}
                value={fileName}
                className="custom-file-input s-margin-right"
                onChange={(e) => {
                  console.log('ONCHANGE', e.target.files);
                  setFile(e.target.files[0]);
                }}
              />
              {!file ? (
                <Button
                  handleClick={handleFileInputClick}
                  content={(
                    <span className="dark-grey-text">
                      {Strings.loadFile}
                    </span>
                  )}
                  customClass="btn-primary absolute top-0 left-0 full-width"
                />
              ) : (
                <div className="absolute top-0 left-0 full-width m-padding-vertical file-selected">
                  <p className="dark-grey-text margin-vertical-0 t-overflow-ellipsis m-margin-horizontal">
                    {file.name}
                  </p>
                  <Button
                    handleClick={() => {
                      setFile(undefined);
                      setFileName('');
                    }}
                    content={<img src={Trash} alt="trash-icon" width={20} className="m-margin-horizontal" />}
                    customClass="btn-icon-letter"
                  />
                </div>
              )}

            </div>
          </div>
          <div className="s-margin-vertical full-width">
            <Input
              id="upload-bill-name"
              onChange={setName}
              value={name}
              label={Strings.billName}
            />
          </div>
          <div className="s-margin-vertical full-width">
            <Input
              id="upload-bill-amount"
              type="number"
              onChange={setAmount}
              value={amount}
              label={Strings.billAmount}
            />
          </div>
          <div className="xl-margin-bottom">
            { name && amount && file
              ? (

                <Button
                  handleClick={onSubmit}
                  content={loading ? <span>LOADING...</span> : <span>{Strings.upload}</span>}
                  customClass="btn-primary"
                />
              )
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBill;
