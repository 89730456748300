export default function appReducer(state, action) {
  switch (action.type) {
    // AUTH REDUCERS
    case 'SET_AUTH': {
      return { ...state, auth: action.payload };
    }
    case 'SET_REFRESH': {
      return { ...state, refresh: action.payload };
    }
    case 'SET_POPOVER': {
      return { ...state, successPopover: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
