import React from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';

const MenuTiles = ({
  onPress, icon, title, boxStyle,
}) => (
  <div className="l-margin">
    <Button
      handleClick={onPress}
      content={(
        <div className={`profile-box-container ${boxStyle}`}>
          <img src={icon} alt="icon" width="40px" className="m-margin-bottom" />
          <span>{title}</span>
        </div>
                )}
      customClass="btn-icon-letter"
    />
  </div>
);

MenuTiles.propTypes = {
  onPress: PropTypes.func,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  boxStyle: PropTypes.string,
};

MenuTiles.defaultProps = {
  onPress: () => {},
  boxStyle: '',
};

export default MenuTiles;
