/* eslint-disable react/jsx-props-no-spreading */
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import './assets/scss/index.scss';

import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { AppProvider } from './store/AppContext';

// Routes
import Routes from './routes/Routes';
import AppPopover from './components/Molecules/AppPopover';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

function App() {
  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <AppPopover />
        <Router>
          <Routes />
        </Router>
      </QueryClientProvider>
    </AppProvider>
  );
}

export default App;
